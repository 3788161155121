import React from 'react';

interface ContextValue {
    authenticated: boolean | null,
    setAuthenticated: any
};

const AppContext = React.createContext<ContextValue>({
    authenticated: null,
    setAuthenticated: (value: any) => {},
});

export default AppContext;