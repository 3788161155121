import React, { useEffect, useCallback } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { Provider, CachePolicies } from 'use-http';
import cookie from 'js-cookie';
import AppContext from './AppContext';
import './scss/style.scss';

type CustomState = DefaultRootState & {
    authenticated: boolean;
};

interface RequestInterceptorProps {
    options: any
};

interface ResponseInterceptorProps {
    response: any
};

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));
const Verify = React.lazy(() => import('./views/login/Verify'));

const App = () => {
    const dispatch = useDispatch();

    const authenticated = useSelector((state: CustomState) => state.authenticated);
    const setAuthenticated = useCallback((bool: boolean) => dispatch({ type: 'set', authenticated: bool }), [dispatch]);

    useEffect(() => {
        const accessToken = cookie.get('accessToken');
        
        setAuthenticated(!!accessToken);
    }, []);

    const httpOptions = {
        cachePolicy: CachePolicies.NO_CACHE,
        interceptors: {
            request: ({ options }: RequestInterceptorProps) => {
                const accessToken = cookie.get('accessToken');

                if (accessToken) {
                    options.headers = {
                        ...options.headers,
                        Authorization: `Bearer ${accessToken}`,
                        'X-Hasura-Role': 'admin',
                    };
                }

                return options;
            },
            response: ({ response }: ResponseInterceptorProps) => {
                const { data } = response;

                const { errors } = data;

                if (errors) {
                    if (errors.some((error: any) => error.extensions?.code === 'invalid-jwt')) {
                        cookie.remove('accessToken');

                        setAuthenticated(false);
                    }
                    else {
                        const [ error ] = errors;

                        throw new Error(error.message);
                    }
                }

                return response;
            },
        },
    };

    if (authenticated === null) {
        return loading;
    }

    return (
        <AppContext.Provider value={{ authenticated, setAuthenticated }}>
            <Provider url={process.env.REACT_APP_API_URL} options={httpOptions}>
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <Switch>
                            <Route
                                exact
                                path="/login"
                                render={props => authenticated ? <Redirect to="/dashboard" /> : <Login {...props} />}
                            />

                            <Route
                                exact
                                path="/login/verify"
                                render={props => authenticated ? <Redirect to="/dashboard" /> : <Verify {...props} />}
                            />

                            <Route
                                path="/"
                                render={props => authenticated ? <TheLayout {...props} /> : <Redirect to="/login" />}
                            />
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </Provider>
        </AppContext.Provider>
    );
};

export default App;